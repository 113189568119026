import React from 'react';
import styled from 'styled-components';
import { styles, colors, components, utils } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';

import Image from '~/components/Image';
import Section from '~/components/Section';
import SidekickAnimation from '~/components/works-with/SidekickAnimation';

export const OTHER_APPS_SLUG = 'other/';

export const APPS = [
  { id: 'gmail', logo: 'gmail', name: 'Gmail', slug: OTHER_APPS_SLUG },
  {
    id: 'front',
    logo: 'front-symbol',
    name: 'Front',
    slug: 'front',
  },
  {
    id: 'intercom',
    logo: 'intercom',
    name: 'Intercom',
    slug: 'intercom',
  },
  {
    id: 'slack',
    logo: 'slack',
    name: 'Slack',
    slug: 'slack-shared-channels',
  },
  {
    id: 'discord',
    logo: 'discord',
    name: 'Discord',
    slug: OTHER_APPS_SLUG + 'discord',
  },
  {
    id: 'openphone',
    logo: 'openphone-symbol',
    name: 'OpenPhone',
    slug: OTHER_APPS_SLUG + 'openphone',
  },
  {
    id: 'message-bird',
    logo: 'messagebird',
    name: 'MessageBird',
    slug: OTHER_APPS_SLUG + 'message-bird',
  },
  {
    id: 'crisp',
    logo: 'crisp',
    name: 'Crisp',
    slug: OTHER_APPS_SLUG + 'crisp',
  },
  {
    id: 'notion',
    logo: 'notion',
    name: 'Notion',
  },
];

export const WORKS_WITH = {
  front: {
    slug: 'front',
    short: 'Front',
    title: <>Herald is the Best Sidekick&nbsp;to&nbsp;Front</>,
    sidekick: true,
    logo: 'front.png',
    screenshot: (
      <div className="screenshot screenshot--front">
        <Image src={`screenshots/front-plugin-1.png`} />
      </div>
    ),
    description: (
      <React.Fragment>
        <p>
          Your team uses Front to collaboratively answer customer inquiries—it’s
          an excellent solution for addressing customer concerns that can be
          resolved right away.
        </p>
        <p>
          <strong>
            But what about questions that can't be addressed immediately? Your
            team can respond by saying "we'll bring this up for consideration,"
            but what happens next? In reality, such requests get lost, or worse,
            your team may make a decision without a data-driven approach.
          </strong>
        </p>
      </React.Fragment>
    ),
  },
  intercom: {
    slug: 'intercom',
    short: 'Intercom',
    title: <>Herald is the Best Sidekick&nbsp;to&nbsp;Intercom</>,
    sidekick: true,
    logo: 'intercom-wordmark.png',
    screenshot: (
      <div className="screenshots">
        <div className="screenshots__wide screenshot">
          <Image src={`screenshots/intercom.png`} />
        </div>
        <div className="screenshots__narrow screenshot">
          <Image src={`screenshots/chrome-extension.png`} />
        </div>
      </div>
    ),
    description: (
      <React.Fragment>
        <p>
          Your team uses Intercom to collaboratively answer customer
          inquiries—it’s an excellent solution for addressing customer concerns
          that can be resolved right away.
        </p>
        <p>
          <strong>
            But what about questions that can't be addressed immediately? Your
            team can respond by saying "we'll bring this up for consideration,"
            but what happens next? In reality, such requests get lost, or worse,
            your team may make a decision without a data-driven approach.
          </strong>
        </p>
      </React.Fragment>
    ),
  },
  slackShared: {
    slug: 'slack-shared-channels',
    short: 'Slack Shared Channels',
    title: (
      <>
        Quantify customer feedback from shared Slack channels to make better
        products
      </>
    ),
    sidekick: false,
    logo: 'slack-wordmark.png',
    screenshot: (
      <div className="screenshot screenshot--slack">
        <Image src="screenshots/slack-app.png" />
      </div>
    ),
    description: (
      <React.Fragment>
        <p>
          Your team uses shared Slack channels to engage with your customers.
          This provides your customers an easy way to get in touch and have
          their concerns addressed quickly. Ultimately, these channels help your
          team understand customer needs and build empathy for them.
        </p>
        <p>
          <strong>
            But many ideas, concerns, and requests presented on these shared
            Slack channels can't be addressed by your team right away. In the
            worst case, they can lead to needless discussions that distract the
            team from existing objectives.
          </strong>
        </p>
      </React.Fragment>
    ),
  },
  slackCommunities: {
    slug: 'slack-communities',
    short: 'Slack Communities',
    title: (
      <>
        Quantify customer feedback from Slack user communities to make better
        products
      </>
    ),
    sidekick: false,
    logo: 'slack-wordmark.png',
    screenshot: (
      <div className="screenshot screenshot--slack">
        <Image src="screenshots/slack-app.png" />
      </div>
    ),
    description: (
      <React.Fragment>
        <p>
          Your team uses Slack to engage with your user community directly. The
          Slack community provides your team an avenue to understand customer
          pain points, address customer concerns, and get new ideas for
          improvements.
        </p>
        <p>
          <strong>
            But a Slack community can become unwieldy—it's a firehose of
            feedback that can be overwhelming for the team to process. There are
            more ideas than your team can (or should) address immediately. So
            naturally, some great ones get lost in the ether and others generate
            needless distraction.
          </strong>
        </p>
      </React.Fragment>
    ),
  },
  custom1: {
    slug: OTHER_APPS_SLUG + 'gmail',
    short: 'Gmail',
  },
  everything: {
    slug: 'any-app',
    short: 'Any App',
    title: <>Quantify customer feedback from any app to make better products</>,
    sidekick: false,
    logo: utils.environment?.firefox ? 'firefox.png' : 'chrome.png',
    logoType: 'symbol',
    screenshot: (
      <div className="screenshot screenshot--other">
        <Image src="screenshots/chrome-extension.png" />
      </div>
    ),
    description: (
      <React.Fragment>
        <p>
          <strong>
            Herald's{' '}
            {utils.environment?.firefox ? 'Firefox Addon' : 'Chrome Extension'}{' '}
            allows your team to tag customer feedback right from any app with a
            single click.
          </strong>{' '}
          To make it as easy as possible to collect feedback, our{' '}
          {utils.environment?.firefox ? 'Firefox Addon' : 'Chrome Extension'}{' '}
          understands data from some of the most popular apps, including:
        </p>
        <div className="collect__supports">
          <div className="collect__logos">
            {[APPS[0], APPS[1], APPS[2], APPS[4], APPS[5], APPS[6]].map(
              (l: any) => {
                const logo = (
                  <components.Tooltip message={l.name || l.id} key={l.id}>
                    <div
                      key={l.id}
                      className={`collect__logos__logo collect__logos__logo--${l.id} `}
                    >
                      <Image src={`logos/${l.logo || l.id}.png`} alt="foo" />
                    </div>
                  </components.Tooltip>
                );
                return logo;
              }
            )}
            <div className="collect__logos__more text-gray">+ More</div>
          </div>
        </div>
      </React.Fragment>
    ),
  },
};

export const findApp = (app?: string) => {
  if (!app) {
    return getOtherApps(APPS[0]);
  }
  const foundApp = APPS.find(
    (a: any) => a.id.toLowerCase() === app.toLowerCase()
  );
  if (foundApp) {
    return getOtherApps(foundApp);
  }
  return getOtherApps(APPS[0]);
};

const getOtherApps = (app: any) => {
  return {
    slug: 'other-apps',
    short: 'Other Apps',
    title: <>Organize and quantify customer feedback from {app.name}</>,
    logo: `${app.logo}.png`,
    logoType: 'symbol',
    screenshot: (
      <div className="screenshot screenshot--other">
        <Image src="screenshots/chrome-extension.png" />
      </div>
    ),
    description: (
      <React.Fragment>
        <p>
          Your team uses {app.name} to talk to customers. {app.name} helps your
          team understand and address customer concerns. It's great for giving
          you customers a direct line to your team to get their questions
          answered quickly.
        </p>
        <p>
          <strong>
            But some concerns can't be resolved with a quick answer. They need
            to be brought to the attention of the product team to consider
            prioritizing. But they often end up lost in the ether, unless
            they're repeated enough or someone on the team becomes biased
            towards solving one of them immediately.
          </strong>
        </p>
      </React.Fragment>
    ),
  };
};

const StyledOpener = styled.div`
  margin: auto;
  margin-top: -4rem;
  padding-top: 5rem;
  background: ${colors.PURPLE_DARK(0.1)};
  .container {
  }
  .container__text {
    margin: auto;
  }
  .container__left {
    flex: 0 0 50%;
    margin-right: 4rem;
  }
  .opener {
    padding: 3rem 0;
  }
  .opener__title {
    text-align: left;
  }
  .screenshot {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: ${styles.BOX_SHADOW};
  }
  .screenshot--slack,
  .screenshot--other {
    margin: auto;
    margin-bottom: -20rem;
    max-width: 32rem;
  }
  .screenshot--front {
    margin-bottom: -20rem;
  }
  .screenshot--other {
    max-width: 30rem;
  }
  .opener__combo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .opener__combo__logo {
    width: 120px;
  }
  .opener__combo__logo--symbol {
    width: 30px;
    height: 30px;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }
  .opener__combo__plus {
    margin: 0 1rem;
  }
  .opener__description {
    margin-top: 3rem;
  }
  .opener__description p {
    margin-bottom: 2rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  .screenshots__wide {
    width: 100% !important;
    top: 2rem !important;
    left: -1rem !important;
  }
  .screenshots__narrow {
    top: 0rem !important;
    right: -1rem !important;
  }
  @media screen and (max-width: 800px) {
    text-align: center;
    .container {
      flex-flow: column;
    }
    .container__left {
      margin: 0;
      margin-bottom: 1rem;
    }
    .opener__title {
      text-align: center;
    }
    .screenshot {
      display: none;
      position: relative;
      height: 12rem;
    }
  }
`;

interface IOpenerProps {
  app?: 'front' | 'slackCommunities' | 'slackShared' | 'intercom';
}

const Opener: React.FC<IOpenerProps> = (props) => {
  const { app } = props;
  const { title, sidekick, logo, logoType, screenshot, description } = app
    ? WORKS_WITH[app] || findApp(app)
    : findApp(app);

  return (
    <StyledOpener>
      <Section className="opener">
        <Container className="container">
          <div className="container__left">
            {logo && (
              <div className="opener__combo">
                <div
                  className={`opener__combo__logo opener__combo__logo--${logoType}`}
                >
                  <Image
                    src={`logos/${logo}`}
                    style={{
                      objectFit: 'contain',
                      objectPosition: 'center right',
                    }}
                  />
                </div>
                <p className="opener__combo__plus">+</p>
                <components.Logo width={100} />
              </div>
            )}
            {screenshot}
          </div>
          <div className="container__right">
            <h2 className="opener__title">{title}</h2>
            {sidekick && <SidekickAnimation app={app} />}
            <div className="opener__description">{description}</div>
          </div>
        </Container>
      </Section>
    </StyledOpener>
  );
};

export default Opener;
