import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { components, colors } from 'herald-fe-shared';

import Image from '~/components/Image';
import { Container } from '~/layout/Layout';
import { USE_CASES } from '~/components/use-cases/Opener';
import { WORKS_WITH } from '~/components/works-with/Opener';

const Styled = styled.footer`
  width: 100vw;
  padding: 3rem 0;
  background: ${colors.BLUE_DARK()};
  position: relative;
  .footer__links {
    max-width: 80rem;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 1fr;
  }
  .footer__links__col {
    margin-top: 0.5rem;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
  }
  .footer__links__col--logo {
    margin: 2rem 0;
  }
  .footer__links__link {
    text-decoration: none;
    margin-bottom: 1rem;
    opacity: 0.6;
    transition: 250ms all;
    font-size: 1rem;
    color: ${colors.WHITE()};
  }
  .footer__links__link:hover {
    opacity: 1;
  }
  label {
    color: ${colors.WHITE(0.7)};
    margin-bottom: 1rem;
  }
  .contact-us p {
    font-size: 1rem;
    color: ${colors.GRAY_2(0.5)};
    margin: 0;
    line-height: 1.5rem;
  }
  .yc-logo {
    margin-left: -5px;
    width: 60%;
    filter: grayscale();
    height: 2rem;
    margin-bottom: 1.5rem;
    opacity: 0.7;
    transition: 250ms all;
    &:hover {
      opacity: 1;
    }
  }
  .social-links {
    display: flex;
    margin-top: 1rem;
  }
  .social-links__link {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    filter: grayscale();
    opacity: 0.7;
    transition: 250ms all;
    &:hover {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 8rem;
    .footer__links {
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }
    .footer__links__col {
      align-items: center;
    }
    .footer__links__logo {
      text-align: center;
    }
    .contact-us {
    }
    .contact-us p,
    .contact-us label {
      text-align: center;
    }
  }
  @media only screen and (max-width: 500px) {
    .footer__links {
      grid-template-columns: 1fr;
      grid-template-rows: min-content;
    }
    .yc-logo {
      width: 30%;
      height: 2rem;
    }
  }
`;

interface IFooterLinkProps {
  text: string;
  link: string;
}

const FooterLink: React.FC<IFooterLinkProps> = (props) => (
  <Link to={props.link} className="footer__links__link">
    {props.text}
  </Link>
);

const Footer: React.FC = () => (
  <Styled>
    <Container className="footer__links">
      <div className="footer__links__logo">
        <Link to="/">
          <components.Logo width={95} color="light" />
        </Link>
        <div className="footer__links__col footer__links__col--logo">
          <FooterLink link="/about/" text="About" />
          <a className="footer__links__link" href="https://portal.herald.fyi">
            Roadmap
          </a>
          <a
            className="footer__links__link"
            href="https://portal.herald.fyi/portal/changelog"
          >
            Changelog
          </a>
          <FooterLink link="/privacy-policy/" text="Privacy Policy" />
          <FooterLink link="/terms/" text="Terms of Service" />
        </div>
      </div>
      <div className="footer__links__col">
        <label>Use Cases</label>
        {USE_CASES.map((u: any) => (
          <FooterLink
            key={u.slug}
            link={`/use-cases/${u.slug}/`}
            text={u.short}
          />
        ))}
      </div>
      <div className="footer__links__col">
        <label>Works With</label>
        {Object.keys(WORKS_WITH).map((key: string) => {
          const u = WORKS_WITH[key];
          return (
            <FooterLink
              key={u.slug}
              link={`/works-with/${u.slug}/`}
              text={u.short}
            />
          );
        })}
      </div>
      <div className="footer__links__col">
        <label>Resources</label>
        <FooterLink link="/userstand/" text="Blog" />
        <a
          className="footer__links__link"
          href="https://datadriventeam.org"
          target="_blank"
        >
          Data Driven Team
        </a>
        <FooterLink link="/pricing/" text="Pricing" />
      </div>
      <div className="footer__links__col">
        <label>Backed By</label>
        <a href="https://ycombinator.com" target="_blank" className="yc-logo">
          <Image src="logos/ycombinator.png" />
        </a>
        <div className="contact-us">
          <p>
            <a href="mailto:hello@heraldhq.com" className="footer__links__link">
              hello@heraldhq.com
            </a>
          </p>
          <p>+1 650-538-8291 (Call or Text)</p>
        </div>
        <div className="social-links">
          <a
            className="social-links__link"
            href="https://twitter.com/askherald"
            target="_blank"
          >
            <Image src="logos/twitter.png" />
          </a>
          <a
            className="social-links__link"
            href="https://www.linkedin.com/company/heraldhq/"
            target="_blank"
          >
            <Image src="logos/linkedin.png" />
          </a>
        </div>
      </div>
    </Container>
  </Styled>
);

export default Footer;
