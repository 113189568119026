import React, { useState, useEffect } from 'react';
import { components, utils, icons } from 'herald-fe-shared';

export const useAuth = () => {
  const [auth, setAuth] = useState(false);
  useEffect(() => {
    setAuth(utils.cookies.getLoggedIn());
  }, []);
  return auth;
};

const CTATry: React.FC<{ primary?: boolean; drop?: boolean }> = (props) => {
  const { primary, drop } = props;
  const auth = useAuth();
  if (!auth) {
    return null;
  }
  return (
    <a href="https://app.heraldhq.com" target="_blank">
      <components.Button
        large={true}
        secondary={primary ? false : true}
        className={`button--try ${drop ? 'drop-at-small' : ''}`}
      >
        {auth ? 'Go to Workspace' : 'Try for Free'}
        {primary && <icons.Arrow style={{ marginLeft: 5 }} />}
      </components.Button>
    </a>
  );
};

export default CTATry;
