import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from 'herald-fe-shared';
import { CSSTransition } from 'react-transition-group';

import Image from '~/components/Image';

const LENGTH_MILLISECONDS = 3000;
const TRANSITION_LENGTH_MILLISECONDS = 500;

const Styled = styled.div`
  margin-top: 3rem;
  display: flex;

  .sidekick__section {
    display: flex;
    align-items: baseline;
  }
  .sidekick__section--left {
    margin-right: 1rem;
  }
  .sidekick__section p {
    font-weight: bold;
    font-size: 1.6rem;
  }
  .sidekick__blank {
    width: 7rem;
    margin: 0 0.5rem;
    border-bottom: 1px dotted ${colors.GRAY_3()};
    position: relative;
  }
  .sidekick__blank__person {
    width: 100%;
    height: 4rem;
    position: absolute;
    bottom: -1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sidekick__blank__person__picture {
    width: 1.5rem;
    height: 4rem;
  }
  .sidekick__blank__person__picture .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  .sidekick__blank__person__name {
    margin-left: 0.5rem;
    font-weight: 400;
    font-family: flood-std, sans-serif;
    color: ${colors.PURPLE_LIGHT()};
  }
  .enter {
    opacity: 0;
    transform: translateY(10px);
  }
  .enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: all ${TRANSITION_LENGTH_MILLISECONDS}ms;
  }
  .exit {
    opacity: 1;
  }
  .exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: all ${TRANSITION_LENGTH_MILLISECONDS}ms;
  }
  @media screen and (max-width: 800px) {
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 420px) {
    flex-flow: column;
    .sidekick__section:first-child {
      margin-bottom: 2rem;
    }
  }
`;

const DUOS = [
  [
    { name: 'Han', id: 'han' },
    { name: 'Chewy', id: 'chewy' },
  ],
  [
    { name: 'Batman', id: 'batman' },
    { name: 'Robin', id: 'robin' },
  ],
  [
    { name: 'Sherlock', id: 'sherlock' },
    { name: 'Watson', id: 'watson' },
  ],
];

const BlankPerson: React.FC<{ person: any; visible: boolean }> = (props) => {
  const { person, visible } = props;
  return (
    <CSSTransition
      in={visible}
      timeout={TRANSITION_LENGTH_MILLISECONDS}
      unmountOnExit={true}
      mountOnEnter={true}
    >
      <div className="sidekick__blank__person">
        <div className="sidekick__blank__person__picture">
          <Image
            src={`sidekicks/${person.id}.png`}
            style={{ objectFit: 'contain' }}
          />
        </div>
        <div className="sidekick__blank__person__name">{person.name}</div>
      </div>
    </CSSTransition>
  );
};

const SidekickAnimation: React.FC<{ app: string }> = (props) => {
  const [duoIndex, setDuoIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const s = duoIndex;
      setDuoIndex(-1);
      setTimeout(() => {
        setDuoIndex(() => {
          if (s === DUOS.length - 1) {
            return 0;
          }
          return s + 1;
        });
      }, TRANSITION_LENGTH_MILLISECONDS);
    }, LENGTH_MILLISECONDS);
    return () => {
      clearInterval(interval);
    };
  }, [duoIndex]);

  return (
    <Styled>
      <div className="sidekick__section sidekick__section--left">
        <p>
          If <span style={{ textTransform: 'capitalize' }}>{props.app}</span> is
        </p>
        <div className="sidekick__blank">
          {DUOS.map((d, i) => (
            <BlankPerson key={i} person={d[0]} visible={duoIndex === i} />
          ))}
        </div>
        <p>,</p>
      </div>
      <div className="sidekick__section">
        <p>Herald is</p>
        <div className="sidekick__blank">
          {DUOS.map((d, i) => (
            <BlankPerson key={i} person={d[1]} visible={duoIndex === i} />
          ))}
        </div>
        <p>.</p>
      </div>
    </Styled>
  );
};

export default SidekickAnimation;
