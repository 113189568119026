import React from 'react';
import { components, colors, icons } from 'herald-fe-shared';

import CTATry, { useAuth } from '~/components/signup/CTATry';

interface ICTAButtonsProps {
  dropDemo?: boolean;
}

const CTAButtons: React.FC<ICTAButtonsProps> = (props) => {
  const { dropDemo } = props;
  const auth = useAuth();
  return (
    <components.ButtonGroup right={true}>
      <CTATry drop={dropDemo} primary={true} />
    </components.ButtonGroup>
  );
};

export default CTAButtons;
