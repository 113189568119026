declare const window: any;

import React, { useState, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { components } from 'herald-fe-shared';

import { SignupContext } from '~/layout/LayoutMarketing';

interface IEmailInputProps {
  demo?: boolean;
}

const Styled = styled.div`
  width: 100%;
  display: flex;
  .email-input__input {
    margin-right: 1rem;
    flex: 1 1 auto;
  }
`;

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const validateEmail = (email: string) =>
  emailRegex.exec(email) ? true : false;

const saveEmail = async (originalEmail?: string, demo?: boolean) => {
  if (originalEmail) {
    const email = originalEmail.toLowerCase();
    const data = await fetch(
      `${process.env.GATSBY_API_BASE_URL}/marketing/request-access`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, demo }),
      }
    ).then((res) => res.json());
    if (window.analytics && data.id) {
      window.analytics.identify(data.id, { email });
    }
    return Promise.resolve(data.id);
  }
  return Promise.reject();
};

const EmailInput: React.FC<IEmailInputProps> = (props) => {
  const { demo } = props;

  const [email, setEmail] = useState('');
  const [validation, setValidation] = useState(false);

  const signup: any = useContext(SignupContext);
  const save = useCallback(() => {
    setValidation(true);
    if (validateEmail(email)) {
      signup.setLoading(true);
      saveEmail(email, demo).then(() => {
        signup.setLoading(false);
        const url = demo
          ? `https://calendly.com/jay-a-patel/30min`
          : `https://airtable.com/embed/shrqfle1QhZ8dhBkU${
              email ? `?prefill_Email=${email}` : ''
            }`;
        window.location.assign(url);
      });
    }
  }, [signup, email]);

  return (
    <Styled>
      <components.Input
        className="email-input__input"
        large={true}
        value={email}
        status={validation && !validateEmail(email) ? 'error' : 'default'}
        message={
          validation && !validateEmail(email)
            ? `Whoops! We need a valid email address.`
            : undefined
        }
        onChange={(e) => setEmail(e.target.value)}
        inputProps={{
          placeholder: 'Enter your work email',
          onKeyDown: (e) => {
            if (e.key === 'Enter') {
              save();
            }
          },
        }}
      />
      <components.Button loading={signup.loading} onClick={save} large={true}>
        {demo ? 'Request Demo' : 'Request Invite'}
      </components.Button>
    </Styled>
  );
};

export default EmailInput;
