import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import LayoutHead from '~/layout/Layout';
import Header from '~/components/Nav';
import Footer from '~/components/Footer';
import SignupModal from '~/components/signup/AirtableForm';

interface IProps {
  navDark?: boolean;
  navScrollPoint?: number;
  promo?: JSX.Element;
  title?: string;
  description?: string;
  image?: string;
  style?: React.CSSProperties;
}

interface ISignupContext {
  open: boolean;
  demo: boolean;
  setOpen: (e: boolean, demo?: boolean) => void;
  loading: boolean;
  setLoading: (e: boolean) => void;
}

export const SignupContext = React.createContext<ISignupContext>({
  open: false,
  loading: false,
  setOpen: () => null,
  setLoading: () => null,
});

const Main = styled.main`
  margin-top: 3.75rem;
  min-height: calc(100vh - 14rem);
`;

const Layout: React.FC<IProps> = (props) => {
  const {
    title,
    description,
    image,
    style,
    promo,
    navDark,
    navScrollPoint,
  } = props;

  // Signup form stuff
  const [open, setOpen] = useState(false);
  const [demo, setDemo] = useState(false);
  const [loading, setLoading] = useState(false);
  const setOpenCallback = useCallback((o: boolean, d?: boolean) => {
    setOpen(o);
    setDemo(d ? true : false);
  }, []);
  const signupContext = {
    open,
    demo,
    setOpen: setOpenCallback,
    loading,
    setLoading,
  };
  // End Signup form

  return (
    <LayoutHead title={title} description={description} image={image}>
      <SignupContext.Provider value={signupContext}>
        <SignupModal />
        <Header
          promo={promo}
          navDark={navDark}
          navScrollPoint={navScrollPoint}
        />
        <Main style={style}>{props.children}</Main>
        <Footer />
      </SignupContext.Provider>
    </LayoutHead>
  );
};

export default Layout;
