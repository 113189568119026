import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { components, icons, colors, styles } from 'herald-fe-shared';

import CTAButtons from '~/components/signup/CTAButtons';

import { Container } from '~/layout/Layout';
import Bars from '~/icons/Bars';
import Cross from '~/icons/Cross';

interface IHeaderProps {
  navDark?: boolean;
  navScrollPoint?: number;
}

const Styled = styled.header`
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: ${styles.ZINDEX_MASK + 5};
  transition: 250ms background;

  &.scrolled {
    background: ${colors.WHITE(0.9)};
    backdrop-filter: blur(4px);
  }
  &.scrolled.dark {
    background: ${colors.BLUE_DARK(0.9)};
    box-shadow: ${styles.BOX_SHADOW};
  }

  .container {
    display: flex;
    justify-content: space-between;
    height: 4rem;
  }
  .container__left {
    display: flex;
  }
  .container__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .header__overflow {
    display: none;
    cursor: pointer;
  }
  .header__logo {
    margin-right: 3rem;
  }
  .header__links--container {
    justify-content: center;
  }
  .header__links {
    justify-content: space-between;
    margin-bottom: -2px;
  }
  .header__cta button.button--primary {
    border: 1px solid ${colors.PURPLE_LIGHT(0)};
  }
  .header__links__link {
    opacity: 0.8;
    transition: 250ms all;
    margin-right: 2rem;
  }
  .header__links__link:hover {
    opacity: 1;
  }
  .header__links__link.active {
    opacity: 1;
  }
  .header__links__link h5 {
    font-size: 1rem;
    color: ${colors.GRAY_4(0.9)};
  }
  .header__links__link.active h5 {
    color: ${colors.PURPLE_DARK()};
  }
  .header__links__exit {
    position: absolute;
    right: 2rem;
    top: 2rem;
    display: none;
  }
  .header__links__exit svg {
    width: 2rem;
    height: 2rem;
    fill: ${colors.GRAY_3()};
  }
  .header__cta {
    justify-content: flex-end;
  }
  .header__cta h5 {
    font-size: 1.2rem;
  }
  .header__cta button {
    height: auto;
    padding: 0.5rem;
  }
  &.dark {
    .header__links__link h5 {
      color: ${colors.WHITE(0.6)};
    }
    .header__links__link.active h5 {
      color: ${colors.WHITE()};
    }
    .header__cta button {
      background: none;
      border: none;
    }
    .header__cta button.button--primary {
      border: 1px solid ${colors.WHITE()};
    }
    .header__cta h5 {
      color: ${colors.WHITE()};
    }
    .header__cta svg {
      fill: ${colors.WHITE()};
    }
    .header__logo svg tspan {
      fill: ${colors.WHITE()};
    }
    .header__logo .circle__holes {
      fill: none;
    }
    .header__logo .circle__shape {
      fill: ${colors.WHITE()};
    }
    .header__overflow svg {
      fill: ${colors.WHITE()};
    }
  }
  @media screen and (max-width: 768px) {
    .header__overflow {
      display: flex;
      margin-right: 1rem;
    }
    .header__logo svg {
      width: 80px;
    }
    .header__links--container {
      z-index: ${styles.ZINDEX_MASK_CONTENT};
      position: fixed;
      flex-flow: column;
      background: ${colors.WHITE(0.95)};
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      visibility: hidden;
      opacity: 0;
      transform: scale(1.2);
      transition: 250ms all;
      &.open {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
      }
    }
    .header__links__link {
      margin: 0;
      margin-top: -2rem;
      margin-bottom: 2rem;
    }
    .header__links__exit {
      display: block;
    }
    .header__links a h5 {
      font-size: 3rem;
      margin: 1rem 0;
    }
    &.dark .header__links__link h5 {
      color: ${colors.GRAY_3()};
    }
    &.dark .header__links__link.active h5 {
      color: ${colors.PURPLE_DARK()};
    }
  }
  .sunset {
    padding: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.BLUE_DARK()};
    p {
      color: white;
      font-weight: bold;
      margin: 0;
      font-size: 14px;
    }
    svg {
      fill: white;
      margin-left: 10px;
      transition: 250ms all;
    }
    &:hover svg {
      transform: translateX(4px);
    }
  }
`;

interface IHeaderLinkProps {
  text: string;
  link: string;
}

const HeaderLink: React.FC<IHeaderLinkProps> = (props) => (
  <Link
    to={props.link}
    activeClassName="active"
    className="header__links__link"
    partiallyActive={true}
  >
    <h5>{props.text}</h5>
  </Link>
);

const SCROLL_POINT = 100;

const Header: React.FC<IHeaderProps> = (props) => {
  const { navDark, navScrollPoint } = props;
  const [navOpen, setNavOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      const y = window.scrollY;
      const max = navScrollPoint || SCROLL_POINT;
      if (y > max && !scrolled) {
        setScrolled(true);
      } else if (y < max && scrolled) {
        setScrolled(false);
      }
    };
  }, [scrolled, setScrolled, navScrollPoint]);

  return (
    <Styled
      className={[
        scrolled ? 'scrolled' : 'not-scrolled',
        navDark ? 'dark' : '',
      ].join(' ')}
    >
      <Link to="/sunset" className="sunset">
        <p>It's time for Herald to say goodbye</p>
        <icons.Arrow />
      </Link>
      <Container className="container">
        <div className="container__left">
          <div
            className="header__overflow flex"
            onClick={() => setNavOpen(true)}
          >
            <Bars fill={colors.PURPLE_DARK()} />
          </div>
          <div className="header__logo flex">
            <Link to="/">
              <components.Logo width={95} color={navDark ? 'light' : 'dark'} />
            </Link>
          </div>
          <div
            className={`header__links--container flex ${
              navOpen ? 'open' : 'closed'
            }`}
          >
            <div
              className="header__links__exit"
              onClick={() => setNavOpen(false)}
            >
              <Cross />
            </div>
            <div className="header__links flex">
              <HeaderLink text="Blog" link="/userstand/" />
            </div>
            <div className="header__links flex">
              <a
                className="header__links__link"
                href="https://portal.herald.fyi"
              >
                <h5>Roadmap</h5>
              </a>
            </div>
            <div className="header__links flex">
              <a
                className="header__links__link"
                href="https://portal.herald.fyi/portal/changelog"
              >
                <h5>Changelog</h5>
              </a>
            </div>
            <div className="header__links flex">
              <HeaderLink text="Pricing" link="/pricing/" />
            </div>
            <div className="header__links flex">
              <HeaderLink text="About" link="/about/" />
            </div>
          </div>
        </div>
        <div className="container__right">
          <div className="header__cta flex">
            <CTAButtons dropDemo={false} />
          </div>
        </div>
      </Container>
    </Styled>
  );
};

export default Header;
